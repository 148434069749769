<template>
  <div class="wrapper">
    <notifications></notifications>
    <side-bar class="d-print-none">
      <div style="padding:0 2rem;margin-bottom: 1rem;" slot="actions">
        <button :disabled="this.companies.length == 0" class="btn btn-primary" style="width: 100%;"
          @click="OpenUpdateCompany">{{ this.company || "Selecionar unidade" }}</button>
      </div>
      <template slot="links">
        <sidebar-item :link="{
          name: 'Assinatura',
          icon: 'ni ni-credit-card text-primary',
        }">
          <sidebar-item :link="{ name: 'Nova Ass. PF', path: '/assinatura/nova/pf' }"></sidebar-item>
          <sidebar-item v-if="getProfile() == 'MANAGER' || getProfile() == 'SUPERVISOR' || getProfile() == 'ADMIN'"
            :link="{ name: 'Nova Ass. PJ (Empresas)', path: '/assinatura/nova/pj' }"></sidebar-item>
          <sidebar-item v-if="getProfile() == 'MANAGER' || getProfile() == 'SUPERVISOR' || getProfile() == 'ADMIN'"
            :link="{ name: 'Ass. PJ por arquivo', path: '/configuracoes/importar-arquivo' }"></sidebar-item>
          <sidebar-item :link="{ name: 'Consultar', path: '/assinatura/consultar' }"></sidebar-item>
        </sidebar-item>

        <sidebar-item :link="{
          name: 'Clientes',
          icon: 'ni ni-circle-08 text-primary',
          path: '/cliente/consultar'
        }">
        </sidebar-item>

        <sidebar-item :link="{
          name: 'Relatórios',
          icon: 'ni ni-single-copy-04 text-primary',
        }">
          <sidebar-item :link="{ name: 'Movimento', path: '/relatorio/movimento' }"></sidebar-item>
          <sidebar-item v-if="getProfile() == 'MANAGER' || getProfile() == 'ADMIN'"
            :link="{ name: 'Operacional', path: '/relatorio/operacional' }"></sidebar-item>

          <sidebar-item v-if="getProfile() == 'MANAGER' || getProfile() == 'ADMIN'" :link="{
            name: 'Inadimplência',
            icon: 'ni ni-single-copy-04 text-primary',
          }">
            <sidebar-item v-if="getProfile() == 'MANAGER' || getProfile() == 'ADMIN'"
              :link="{ name: 'Demais', path: '/relatorio/inadimplencia' }"></sidebar-item>
            <sidebar-item v-if="getProfile() == 'MANAGER' || getProfile() == 'ADMIN'"
              :link="{ name: 'Cartões Crédito', path: '/relatorio/inadimplencia-cartao' }"></sidebar-item>
          </sidebar-item>
          <sidebar-item v-if="getProfile() == 'MANAGER' || getProfile() == 'ADMIN'"
            :link="{ name: 'Lead', path: '/relatorio/lead' }"></sidebar-item>
          <sidebar-item v-if="getProfile() == 'MANAGER' || getProfile() == 'ADMIN'"
            :link="{ name: 'Produtividade', path: '/relatorio/produtividade' }"></sidebar-item>
          <sidebar-item v-if="getProfile() == 'MANAGER' || getProfile() == 'SUPERVISOR' || getProfile() == 'ADMIN'"
            :link="{ name: 'Produtividade/Atendente', path: '/relatorio/produtividade-atendente-plano' }"></sidebar-item>
          <sidebar-item v-if="getProfile() == 'MANAGER' || getProfile() == 'ADMIN'"
            :link="{ name: 'Recebimento', path: '/relatorio/recebimento' }"></sidebar-item>
          <sidebar-item v-if="getProfile() == 'MANAGER' || getProfile() == 'ADMIN'"
            :link="{ name: 'Forma Pagamento', path: '/relatorio/forma-pagamento' }"></sidebar-item>
          <sidebar-item v-if="getProfile() == 'MANAGER' || getProfile() == 'ADMIN'"
            :link="{ name: 'Vidas PJ', path: '/relatorio/vidas-pj' }" />
          <sidebar-item v-if="getProfile() == 'MANAGER' || getProfile() == 'ADMIN'"
            :link="{ name: 'Dashboard', path: '/home' }" />
        </sidebar-item>

        <sidebar-item v-if="getProfile() == 'MANAGER' || getProfile() == 'SUPERVISOR' || getProfile() == 'ADMIN'" :link="{
          name: 'Configurações',
          icon: 'ni ni-settings text-primary',
        }">
          <sidebar-item v-if="getProfile() == 'MANAGER' || getProfile() == 'SUPERVISOR' || getProfile() == 'ADMIN'"
            :link="{ name: 'Planos PF', path: '/configuracoes/plano/PF' }"></sidebar-item>
          <sidebar-item v-if="getProfile() == 'MANAGER' || getProfile() == 'SUPERVISOR' || getProfile() == 'ADMIN'"
            :link="{ name: 'Planos PJ', path: '/configuracoes/planopj/PJ' }"></sidebar-item>
          <sidebar-item v-if="getProfile() == 'MANAGER' || getProfile() == 'ADMIN'"
            :link="{ name: 'Usuário', path: '/configuracoes/usuario' }"></sidebar-item>
          <sidebar-item v-if="getProfile() == 'MANAGER' || getProfile() == 'ADMIN'"
            :link="{ name: 'Ratificação', path: '/configuracoes/ratificacao' }"></sidebar-item>
          <sidebar-item v-if="getProfile() == 'MANAGER' || getProfile() == 'ADMIN'"
            :link="{ name: 'SMS', path: '/configuracoes/parametros' }"></sidebar-item>
          <sidebar-item v-if="getProfile() == 'MANAGER' || getProfile() == 'ADMIN'"
            :link="{ name: 'Assinatura', path: '/configuracoes/parametros/assinatura' }"></sidebar-item>
          <sidebar-item v-if="getProfile() == 'MANAGER' || getProfile() == 'ADMIN'"
            :link="{ name: 'Motivos Cancelamento', path: '/configuracoes/motivos' }"></sidebar-item>
          <sidebar-item v-if="getProfile() == 'MANAGER' || getProfile() == 'ADMIN'"
            :link="{ name: 'Unidades', path: '/configuracoes/unidades' }"></sidebar-item>
        </sidebar-item>

        <sidebar-item
          :link="{ name: 'Meu Perfil', path: '/configuracoes/meu-perfil', icon: 'ni ni-badge text-primary' }" />
        <sidebar-item :link="{ name: 'Sair', path: '/sair', icon: 'ni ni-button-power text-primary' }" />

      </template>
    </side-bar>

    <div class="main-content">

      <modal :show.sync="modals.companies" :fecharClick="false">
        <h3 class="text-center">Unidade</h3>
        <div style="display: grid; gap: 1rem; grid-template-columns: 1fr 1fr;">
          <button class="btn btn-primary" style="width: 100%" v-for="company in companies" v-bind:key="company.id"
            @click="updateCompany(company.id)">
            {{ company.name }}
          </button>
        </div>
      </modal>

      <div @click="$sidebar.displaySidebar(false)">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <router-view></router-view>
        </fade-transition>
      </div>
    </div>

  </div>
</template>
<script>
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}
import { FadeTransition } from 'vue2-transitions';

export default {
  components: {
    FadeTransition
  },
  data() {
    return {
      company: null,
      companies: [],
      modals: {
        companies: false
      }
    }
  },
  mounted() {
    const companyId = sessionStorage.getItem("company")
    this.loadCompanies(companyId)
  },
  methods: {
    OpenUpdateCompany() {
      this.modals.companies = true
    },
    updateCompany(companyId) {
      sessionStorage.setItem("company", companyId)
      const { name } = this.companies.find(company => company.id == companyId)

      this.company = name
      this.modals.companies = false
    },
    loadCompanies(companyId) {
      return new Promise(resolve => {
        resolve(this.$clubApi.get("/company")
          .then(res => {
            this.companies = res.data.object.filter(company => company.enabled)

            if (companyId) {
              this.updateCompany(companyId)
            } else {
              this.OpenUpdateCompany()
            }
          }))
      })
    },
    getProfile() {
      return localStorage.getItem("profile")
    },
    initScrollbar() {
      let docClasses = document.body.classList;
      let isWindows = navigator.platform.startsWith('Win');
      if (isWindows) {
        initScrollbar('scrollbar-inner');
        docClasses.add('perfect-scrollbar-on');
      } else {
        docClasses.add('perfect-scrollbar-off');
      }
    }
  },
  updated: function () {
    if (this.$recaptcha) {
      if (this.$route.fullPath.startsWith("/assinatura/nova")) {
        this.$recaptcha.showBadge()
      } else {
        this.$recaptcha.hideBadge()
      }
    }
  }
};

</script>
<style lang="scss"></style>
