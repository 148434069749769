import Vue from 'vue'
import Router from 'vue-router'
import axios from 'axios'

import AreaExternaLayout from '@/views/Layout/AreaExternaLayout.vue';
import AreaLogadaLayout from '@/views/Layout/AreaLogadaLayout.vue';

const Home = () => import('@/views/Home.vue');
const Sair = () => import('@/views/Sair.vue');
const Login = () => import('@/views/Login.vue');
const EsqueciSenha = () => import('@/views/EsqueciSenha.vue');

//assinatura
const NovaAssinaturaPJ = () => import('@/views/NovaAssinaturaPJ.vue');
const NovaAssinaturaPF = () => import('@/views/NovaAssinaturaPF.vue');
const ControleAssinatura = () => import('@/views/ControleAssinatura.vue');
const ConsultaAssinatura = () => import('@/views/ConsultaAssinatura.vue');

//cliente / titular
const ConsultaCliente = () => import('@/views/ConsultaCliente.vue');
const ControleCliente = () => import('@/views/ControleCliente.vue');

//Relatorio
const RelatorioMovimento = () => import('@/views/RelatorioMovimento.vue');
const RelatorioOperacional = () => import('@/views/RelatorioOperacional.vue');
const RelatorioInadimplencia = () => import('@/views/RelatorioInadimplencia.vue');
const RelatorioInadimplenciaCard = () => import('@/views/RelatorioInadimplenciaCard.vue');
const RelatorioLead = () => import('@/views/RelatorioLead.vue');
const RelatorioProdutividade = () => import('@/views/RelatorioProdutividade.vue');
const RelatorioAtendentePlano = () => import('@/views/RelatorioAtendentePlano.vue');
const RelatorioRecebimento = () => import('@/views/RelatorioRecebimento.vue');
const RelatorioFormaPagamento = () => import('@/views/RelatorioFormaPagamento.vue');
const RelatorioVidasPJ = () => import('@/views/RelatorioVidasPJ.vue');

//Configuracoes
const ControlePlano = () => import('@/views/ControlePlano.vue');
const ConsultaPlano = () => import('@/views/ConsultaPlano.vue');
const ConsultaUnidade = () => import('@/views/ConsultaUnidade.vue');
const ConsultaPlanoPJ = () => import('@/views/ConsultaPlanoPJ.vue');
const ControleUsuario = () => import('@/views/ControleUsuario.vue');
const ConsultaUsuario = () => import('@/views/ConsultaUsuario.vue');
const Ratificacao = () => import('@/views/Ratificacao.vue');
const Parametros = () => import('@/views/Parametros.vue');
const ParametrosAssinatura = () => import('@/views/ParametrosAssinatura.vue');
const ConsultaMotivos = () => import('@/views/ConsultaMotivos.vue');
const ControleMotivos = () => import('@/views/ControleMotivos.vue');
const ImportarArquivo = () => import('@/views/ImportarArquivo.vue');

const MeuPerfil = () => import('@/views/MeuPerfil.vue');

//Outros
const PrevisaoFatura = () => import('@/views/PrevisaoFatura.vue');
const CarneAssinatura = () => import('@/views/CarneAssinatura.vue');

let rotasLogin = {
  path: '/login',
  component: AreaExternaLayout,
  redirect: '/login',
  name: 'Login',
  children: [
    {
      path: '/',
      component: Login
    },
    {
      path: 'esqueci-senha',
      component: EsqueciSenha
    }
  ]
};

let rotasExternas = {
  path: '/extras',
  component: AreaExternaLayout,
  redirect: '/extras',
  name: 'Extras',
  children: [
    {
      path: 'impressao-carne/:subscriptionId',
      props: true,
      component: CarneAssinatura
    },
    {
      path: 'preview-fatura',
      props: true,
      component: PrevisaoFatura
    }
  ]
};

let rotasHome = {
  path: '/home',
  component: AreaLogadaLayout,
  redirect: '/home',
  name: 'Home',
  children: [
    {
      path: '/',
      component: Home
    },
    {
      path: '/sair',
      component: Sair
    }
  ]
};

let rotasAssinatura = {
  path: '/assinatura',
  component: AreaLogadaLayout,
  redirect: '/assinatura',
  name: 'Assinatura',
  children: [
    {
      path: 'nova/pf',
      component: NovaAssinaturaPF
    },
    {
      path: 'nova/pj',
      component: NovaAssinaturaPJ
    },
    {
      path: 'consultar',
      component: ConsultaAssinatura
    },
    {
      path: ':subscriptionId',
      props: true,
      component: ControleAssinatura
    }
  ]
};

let rotasClientes = {
  path: '/cliente',
  component: AreaLogadaLayout,
  redirect: '/cliente',
  name: 'Cliente',
  children: [
    {
      path: 'consultar',
      component: ConsultaCliente
    },
    {
      path: ':holderId',
      props: true,
      component: ControleCliente
    },
    {
      path: ':holderId/:subscriptionId',
      props: true,
      component: ControleCliente
    }
  ]
};

let rotasRelatorios = {
  path: '/relatorio',
  component: AreaLogadaLayout,
  redirect: '/relatorio',
  name: 'Relatorio',
  children: [
    {
      path: 'movimento',
      component: RelatorioMovimento
    },
    {
      path: 'operacional',
      component: RelatorioOperacional
    },
    {
      path: 'inadimplencia',
      component: RelatorioInadimplencia
    },
    {
      path: 'inadimplencia-cartao',
      component: RelatorioInadimplenciaCard
    },
    {
      path: 'lead',
      component: RelatorioLead
    },
    {
      path: 'produtividade',
      component: RelatorioProdutividade
    },
    {
      path: 'produtividade-atendente-plano',
      component: RelatorioAtendentePlano
    },
    {
      path: 'recebimento',
      component: RelatorioRecebimento
    },
    {
      path: 'forma-pagamento',
      component: RelatorioFormaPagamento
    },
    {
      path: 'vidas-pj',
      component: RelatorioVidasPJ
    }
  ]
};

let rotasConfiguracoes = {
  path: '/configuracoes',
  component: AreaLogadaLayout,
  redirect: '/configuracoes',
  name: 'Configuracoes',
  children: [
    {
      path: 'plano/editar/:id',
      props: true,
      component: ControlePlano
    },
    {
      path: 'plano/novo/:typeSub',
      props: true,
      component: ControlePlano
    },
    {
      path: 'plano/:typeSub',
      props: true,
      component: ConsultaPlano
    },
    {
      path: 'planopj/:typeSub',
      props: true,
      component: ConsultaPlanoPJ
    },
    {
      path: 'usuario/editar/:id',
      props: true,
      component: ControleUsuario
    },
    {
      path: 'usuario/novo',
      component: ControleUsuario
    },
    {
      path: 'usuario',
      component: ConsultaUsuario
    },
    {
      path: 'ratificacao',
      component: Ratificacao
    },
    {
      path: 'parametros',
      component: Parametros
    },
    {
      path: 'parametros/assinatura',
      component: ParametrosAssinatura
    },
    {
      path: 'motivos',
      component: ConsultaMotivos
    },
    {
      path: 'motivos/novo',
      component: ControleMotivos
    },
    {
      path: 'motivos/editar/:id',
      props: true,
      component: ControleMotivos
    },
    {
      path: 'meu-perfil',
      component: MeuPerfil
    },

    {
      path: 'importar-arquivo',
      component: ImportarArquivo
    },
    {
      path: 'unidades',
      component: ConsultaUnidade
    }
  ]
};

const routes = [
  rotasLogin,
  rotasExternas,
  rotasHome,
  rotasAssinatura,
  rotasClientes,
  rotasRelatorios,
  rotasConfiguracoes,
  {
    path: '*',
    redirect: '/home'
  }
];

Vue.use(Router)
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes
})

router.beforeResolve((to, from, next) => {
  if (to.path == '/' || to.path == '/login' || to.path == '/login/esqueci-senha' || to.path == '/extras/preview-fatura') {
    next()
  } else {
    axios.get(process.env.VUE_APP_API_URL + '/user/data',
      { headers: { 'token': localStorage.getItem("token") } }
    ).then((response) => {
      localStorage.setItem("name", response.data.object.name)
      localStorage.setItem("profile", response.data.object.profile)
      next()
    }).catch(() => {
      next('/login')
    })
  }
})

export default router
